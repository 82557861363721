@use '@virginmediao2/momentum-css/react/components/icon';

.icon--color-light {
  color: var(--surface-bg);
}

.icon--color-dark {
  color: var(--surface-fg);
}

.icon--color-inherit {
  color: inherit;
}

/** From Latest Momentum Icon **/

.icon__host {
  display: flex;
  place-content: center;
  place-items: center;
  width: 100%;
  height: 100%;
}

.icon__mask {
  display: flex;
  width: 100%;
  height: calc(100% * var(--icon-ratio));
  vertical-align: middle;
  background: currentcolor;
  // stylelint-disable-next-line custom-property-pattern
  mask-image: var(--_maskUrl);
  mask-repeat: no-repeat;
  mask-position: center center;
  mask-size: contain;
}
