.image-link {
  position: relative;
  display: grid;
  margin-bottom: var(--padding-s);
}

.image-link__image {
  grid-area: 1 / 1;
}

.image-link__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-link__content {
  display: flex;
  flex-direction: column;
  grid-area: 1 / 1;
  gap: var(--space-between-xs);
  padding-block: var(--mo-sizing-sm);
  padding-inline: var(--padding-s);
  padding-right: 25%;
  padding-left: var(--mo-sizing-sm);
}

.image-link__content span {
  white-space: nowrap;
}
