@use '@virginmediao2/momentum-css/react/components/anchor';

.anchor:not(.anchor--is-image, .anchor--icon) {
  display: inline;
}

.anchor--expand::before {
  position: absolute;
  inset: 0;
  display: block;
  cursor: pointer;
  content: '';
}

.anchor.anchor--color-light,
.anchor.anchor--color-light:hover,
.anchor.anchor--color-light:focus-visible {
  color: var(--color-surface-bg);
}

.anchor--color-dark,
.anchor--color-dark:hover,
.anchor--color-dark:focus-visible {
  color: var(--color-surface-fg);
}

.anchor:not(.anchor--color-inherit):focus-visible,
.anchor:not(.anchor--color-inherit):hover {
  --_color: var(--color-custom-link-fg);
}

.anchor__text {
  text-decoration-thickness: 1px;
}
