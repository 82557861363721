@use '@virginmediao2/momentum-css/react/components/card';

.card--no-border {
  border: 0;
}

.card__media {
  place-content: center;
  place-items: center;
}

.card {
  position: relative;
  overflow: visible;
}
