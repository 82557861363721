.components {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  gap: 16px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.components__item {
  padding: 16px;
  background-color: var(--color-surface-bg-dim);
}
