.responsive-image {
  --_object-fit: unset;
}

.responsive-image--contain {
  --_object-fit: contain;
}

.responsive-image--cover {
  --_object-fit: cover;
}

.responsive-image__img {
  width: 100%;
  height: auto;
  object-fit: var(--_object-fit);
}
