.country {
  display: flex;
  flex-direction: column;
  gap: var(--space-between-m);
  width: 100%;
}

.country__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.country__info {
  margin-top: var(--padding-m);
}

.country__rates-table {
  text-align: left;
}

.country__rates--paym-msg {
  margin-top: 16px;
  margin-bottom: 16px;
}

.country__rates--pay-ff-msg {
  margin-top: 10px;
  margin-bottom: 10px;
}

.country__rates-table > dl {
  gap: 2px;
}

.country__rates-tablehead {
  width: 100%;
  padding: 10px;
  margin-bottom: 2px;
  background-color: var(--mo-color-10);
}

.country__rates-table > dl > dt,
.country__rates-table > dl > dd {
  padding: 10px;
  background-color: var(--mo-color-05);
}

.country__datapoints {
  display: flex;
  flex-direction: column;
  gap: var(--space-between-s);
  margin-bottom: var(--padding-m);
}

@media (width >= 30em) {
  .country__datapoints {
    flex-direction: row;
    gap: var(--space-between-m);
  }
}

.country__datapoint {
  display: flex;
  gap: 2px;
  align-items: center;
}

.country__payment--toggles {
  display: flex;
  gap: var(--space-between-m);
  margin-top: var(--padding-m);
}
