/* stylelint-disable selector-pseudo-class-no-unknown */

.livechat :last-child:empty::before {
  font-weight: var(--font-weight-bold);
  content: 'Go to live chat';
}

/**
 * By using :global here, we are able to instruct CSS Modules
 * not to obfuscate the class names, so that the LivePerson
 * chat widget can be styled correctly.
 */

/**
https://stackoverflow.com/questions/11078509/how-to-increase-the-clickable-area-of-a-a-tag-button
*/
.livechat :global .button__text {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 1em 2em;
  margin: -1em -2em;
  font-weight: var(--font-weight-bold);
  cursor: pointer;

  --zindex-shout-out-base: 10;
}

.livechat :global .LPMcontainer {
  margin: 0 !important;
  cursor: pointer;
}
