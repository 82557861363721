.dynamo-layout {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: var(--space-between-l);
  column-gap: var(--space-between-m);
}

.dynamo-layout__main,
.dynamo-layout__aside {
  grid-column: 1 / span 12;
}

@media (width >= 64em) {
  .dynamo-layout__main {
    grid-column: 1 / span 8;
  }

  .dynamo-layout__aside {
    grid-column: 9 / span 4;
  }
}
