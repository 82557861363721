.loader {
  position: relative;
  display: block;
  width: 48px;
  height: 48px;
  margin: auto;
  border-radius: 50%;
  animation: rotate 1s linear infinite;
}

.loader::before {
  position: absolute;
  inset: 0;
  box-sizing: border-box;
  content: '';
  border: 5px solid var(--color-primary-bg);
  border-radius: 50%;
  animation: spinner-animation 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-animation {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
