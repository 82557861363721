.embed {
  display: block;
}

.embed__container {
  height: 0;
  overflow: hidden;
}

.embed--loaded .embed__container {
  height: unset;
  overflow: unset;
}
