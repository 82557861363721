@use '@virginmediao2/momentum-css/dist/components/breadcrumbs';

.breadcrumbs__list {
  width: max-content;
}

.breadcrumbs__list-item--sticky {
  position: sticky;
  left: 0;
  background: var(--color-surface-bg);
  box-shadow: 0 0 12px 8px var(--color-surface-bg);
}

.breadcrumbs__list-item:last-child {
  overflow: unset;
}

.breadcrumbs__list-item:nth-child(1n + 3) {
  margin: unset;
}
