.submit-button-container {
  margin-top: 28px;
}

.submit-button-container > * > * > * > input {
  --cursor: pointer;
}

.form__item--min-length {
  flex: 0 1 400px;
}

.tab--no-overflow {
  overflow: hidden;
}
