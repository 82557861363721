@use '@virginmediao2/momentum-css/react/components/form-input-control';
@use '@virginmediao2/momentum-css/react/components/form-multi-item';

.form-input-control:has(input:checked),
.form-input-control:has(input:checked:focus-visible) {
  --control-icon-display: flex;
  --control-background-color: var(--color-success-bg);
  --control-border-color: var(--color-success-bg);
  --control-color: var(--color-success-fg);
}

.form-input-control--is-invalid.form-input-control:has(input:checked),
.form-input-control--is-invalid.form-input-control:has(
    input:checked:focus-visible
  ) {
  --control-background-color: var(--color-success-bg);
  --control-border-color: var(--color-error-bg-dim);
  --control-color: var(--color-success-fg);
}
