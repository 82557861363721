.searchbox {
  display: grid;
  grid-template-areas: 'input' 'submit';
  gap: var(--space-between-m);
  margin-bottom: 16px;
}

@media (width >= 48em) {
  .searchbox {
    grid-template-areas: 'input submit';
    grid-template-columns: 30rem 1fr;
  }
}

.searchbox__input {
  grid-area: input;
}

.searchbox__submit {
  display: flex;
  grid-area: submit;
}
