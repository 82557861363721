.grid {
  --_grid-columns: 1;

  display: grid;
  grid-template-columns: repeat(var(--_grid-columns), 1fr);
  gap: 16px;
}

.grid--columns-sm-1 {
  --_grid-columns: 1;
}

.grid--columns-sm-2 {
  --_grid-columns: 2;
}

.grid--justify-items-start {
  justify-items: start;
}

.grid--justify-items-center {
  justify-items: center;
}

.grid--justify-items-end {
  justify-items: end;
}

.grid--justify-items-stretch {
  justify-items: stretch;
}

.grid--justify-items-left {
  justify-items: left;
}

.grid--justify-items-right {
  justify-items: right;
}

.grid--align-content-start {
  align-content: start;
}

.grid--align-content-center {
  align-content: center;
}

.grid--align-content-end {
  align-content: end;
}

.grid--height-auto {
  height: auto;
}

.grid--height-full {
  height: 100%;
}

.grid--height-screen {
  height: 100vh;
}

@media (width >= 48em) {
  .grid {
    gap: 24px;
  }

  .grid--columns-md-1 {
    --_grid-columns: 1;
  }

  .grid--columns-md-2 {
    --_grid-columns: 2;
  }

  .grid--columns-md-3 {
    --_grid-columns: 3;
  }

  .grid--columns-md-4 {
    --_grid-columns: 4;
  }

  .grid--columns-md-5 {
    --_grid-columns: 5;
  }
}

@media (width >= 64em) {
  .grid--columns-lg-1 {
    --_grid-columns: 1;
  }

  .grid--columns-lg-2 {
    --_grid-columns: 2;
  }

  .grid--columns-lg-3 {
    --_grid-columns: 3;
  }

  .grid--columns-lg-4 {
    --_grid-columns: 4;
  }

  .grid--columns-lg-5 {
    --_grid-columns: 5;
  }

  .grid--columns-lg-6 {
    --_grid-columns: 6;
  }
}
